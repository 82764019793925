import {
  InputHelperMessage,
  InputHelperMessageProps,
} from '../input-helper-message';
import { InputLabel, InputLabelProps } from '../input-label';
import { InputUnderline, InputUnderlineProps } from '../input-underline';
import {
  Theme,
  TypographyVariant,
  WithTheme,
  bem,
  cx,
  interpolateFontSize,
  interpolateRhythmMargin,
} from '@mint/core';
import { useFocus, useFormControlClassNames, useValue } from '../shared';
import { BaseInput } from '../base-input';
import { InputProps } from './input-props';
import React from 'react';
import styled from 'styled-components';

/**
 * Base input root class name applied to the input element.
 */
const ROOT_CLASS_NAME = 'input';

/**
 * BEM block name for the composed label component.
 */
const LABEL_BLOCK_NAME = 'label';

/**
 * BEM block name for the composed base input component.
 */
const INPUT_BLOCK_NAME = 'input';

/**
 * BEM block name for the underline component.
 */
const UNDERLINE_BLOCK_NAME = 'underline';

/**
 * Default component for wrapper element.
 */
const DEFAULT_COMPONENT = 'div';

/**
 * Helper function to creat the helper text ID.
 * @param id Input `id` property.
 */
const makeHelperTextId = (id?: string): string | undefined =>
  id ? `${id}__helper-text` : undefined;

/**
 * Helper function to make wrapper component properties.
 * @param props Input properties object.
 * @param theme Resolved theme instance.
 * @param focused Flag indicating if the input has focus.
 */
const makeComponentProps = (
  props: InputProps,
  focused: boolean,
  hasValue: boolean,
): React.HTMLAttributes<htmlelement> => {
  const classes = bem(ROOT_CLASS_NAME);
  return {
    className: cx(
      ROOT_CLASS_NAME,
      useFormControlClassNames(ROOT_CLASS_NAME, { ...props, focused }),
      props.dense && classes.dense(),
      hasValue ? classes.hasValue() : classes.empty(),
      props.className,
    ),
  };
};

/**
 * Helper function to make base input properties.
 * @param props Input properties object.
 * @param theme Resolved theme instance.
 * @param focused Flag indicating if the input has focus.
 */
const makeBaseInputProps = (
  props: InputProps,
  focused: boolean,
): InputProps => {
  const className = bem.block(ROOT_CLASS_NAME, INPUT_BLOCK_NAME);
  const classes = bem(className);
  const { dense, disabled, error, id, warning } = props;
  return {
    'aria-describedby': makeHelperTextId(id),
    className: cx(
      useFormControlClassNames(className, { ...props, focused }),
      dense && classes.dense(),
    ),
    disabled,
    dense,
    error,
    hidePlaceholder: props.label != null && !focused,
    warning,
  };
};

/**
 * Helper function to create label component properties.
 * @param props Input properties object.
 * @param focused Flag indicating if the input has focus.
 */
const makeLabelProps = (
  props: InputProps,
  focused: boolean,
  hasValue: boolean,
): InputLabelProps => {
  const { dense, error, warning } = props;
  const classes = bem(ROOT_CLASS_NAME, LABEL_BLOCK_NAME);
  return {
    className: cx(classes.className, dense && classes.dense()),
    dense,
    error,
    focused,
    htmlFor: props.id,
    shrink: focused || hasValue,
    warning,
  };
};

/**
 * Helper function create create underline component properties.
 * @param props Input properties object.
 * @param theme Resolved theme instance.
 * @param focused Flag indicating if the input has focus.
 */
const makeUnderlineProps = (
  props: InputProps,
  focused: boolean,
): InputUnderlineProps => {
  const classes = bem(ROOT_CLASS_NAME, UNDERLINE_BLOCK_NAME);
  const { dense, disabled, error, warning } = props;
  return {
    className: cx(
      classes.className,
      useFormControlClassNames(classes.className, { ...props, focused }),
      dense && classes.dense(),
    ),
    disabled,
    error,
    focused,
    warning,
  };
};

/**
 * Helper function to create helper text component properties.
 * @param props Input properties object.
 * @param focused Flag indicating if the input has focus.
 */
const makeHelperMessageProps = (
  props: InputProps,
  focused: boolean,
): InputHelperMessageProps => {
  const { error, id, warning } = props;
  return { error, focused, id: id ? makeHelperTextId(id) : undefined, warning };
};

/**
 * `Input` component implementation before styles are applied.
 */
const InputComponent = React.forwardRef<htmlinputelement, InputProps="">(
  (props, ref): React.ReactElement => {
    const {
      children,
      className,
      component: Component = DEFAULT_COMPONENT,
      dense,
      disabled,
      error,
      helperText,
      id,
      label,
      onBlur,
      onChange,
      onFocus,
      value: valueProp,
      warning,
      ...rest
    } = props;

    const [focused, bindFocus] = useFocus({ onBlur, onFocus });
    const [value, handleChange] = useValue({
      onChange,
      value: valueProp as string,
    });

    const hasValue = value.length > 0;

    return (
      <component {...makeComponentProps(props,="" focused,="" hasValue)}="">
        {label != null && (
          <inputlabel {...makeLabelProps(props,="" focused,="" hasValue)}="">
            {etikett}
          </inputlabel>
        )}
        <baseinput {...makeBaseInputProps(props,="" focused)}="" {...rest}="" {...bindFocus}="" id="{id}" ref="{ref}" onChange="{handleChange}" value="{value}"></baseinput>
        <inputunderline {...makeUnderlineProps(props,="" focused)}=""></inputunderline>
        {helperText != null && (
          <inputhelpermessage {...makeHelperMessageProps(props,="" focused)}="">
            {hjälptext}
          </inputhelpermessage>
        )}
      </component>
    );
  },
);

InputComponent.displayName = "Inmatning";

const pickTypographyVariant = (
  props: MedTema<inputprops>,
): TypographyVariant => (props.dense ? 'dense' : 'control');

const paddingTop = (props: MedTema<inputprops>): string =>
  props.label ? '1em' : '0';

export const Input = styled(InputComponent)`
  padding-top: ${paddingTop};
  position: relative;
  ${interpolateRhythmMargin(pickTypographyVariant)}
  ${interpolateFontSize(pickTypographyVariant)}

  ${InputHelperMessage} {
    margin-top: 0.2em;
  }

  ${InputLabel} {
    position: absolute;
  }

  ${BaseInput}::placeholder {
    transition: opacity 200ms;
  }
`;
</inputprops></inputprops></htmlinputelement,></htmlelement>
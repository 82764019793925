/**
 * Lägger till egenskaperna för ett eller flera källobjekt till ett målobjekt. Fungerar exakt som
 * `Object.assign`, men som ett verktyg för att upprätthålla stöd för IE 11.
 *
 * @se https://github.com/apollostack/apollo-client/pull/1009
 */
export funktion assign<a, B="">(a: A, b: B): A & B;
exportfunktion tilldela<a, B,="" C="">(a: A, b: B, c: C): A & B & C;
exportfunktion tilldela<a, B,="" C,="" D="">(a: A, b: B, c: C, d: D): A & B & C & D;
exportfunktion tilldela<a, B,="" C,="" D,="" E="">(
  a: A,
  b: B,
  c: C,
  d: D,
  e: E,
): A & B & C & D & E;
export function assign(target: any, ...sources: Array<any>): any;
export function assign(
  target: { [key: string]: any },
  ...sources: Array<{ [key: string]: any }>
): { [key: string]: any } {
  sources.forEach(source => {
    if (typeof source === 'undefined' || source === null) {
      return;
    }
    Object.keys(source).forEach(key => {
      target[key] = source[key];
    });
  });
  return target;
}
</any></a,></a,></a,></a,>
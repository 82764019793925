/**
 * Verktygsfunktion för att tillämpa klassnamn.
 * @param input Klassnamnssträng(ar), matriser eller kartor.
 */
export const klassnamn = (
  ...input: Array<string |="" string[]="" boolean="" null="" undefined="">
): string =>
  input
    .reduce((curr: string[], val) => {
      if (Array.isArray(val)) {
        val.forEach(v => curr.push(v));
      } else if (typeof val === 'string') {
        curr.push(val.trim());
      }
      return curr;
    }, [])
    .join(' ');
</string>